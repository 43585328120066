
import searchByBFE from "~/graphql/Property/searchByBFE.gql";
import DownloadAct from "~/graphql/Download/DownloadAct.gql";
import DownloadEasement from "~/graphql/Download/DownloadEasement.gql";
import estaidEasementForCurrentUser from "~/graphql/Property/EstaidEasement.gql";
import { createEasementsSheet, writeSheetsToXSLXAndSave } from "~/helpers/download-helpers.js";
import { createEasementDateId } from "~/helpers/property-helpers";
import { getPropertyAddress, getPropertyType } from "~/helpers/property-helpers.js";

export default {
  components: {
    EstaidEasementDialog: () => import("../components/estaid-easement/EstaidEasementDialog.vue"),
  },
  props: {
    properties: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      activeId: 0,
      easements: [],
      loadingStates: [],
      hasLoadedStates: [],
      sortOrder: "asc",
      searchTerm: "",
      showEstaidEasements: false,
      activeEasement: {},
      headers: [
        { name: this.$t("EASEMENT_PRIORITY_TOOLTIP"), active: true, sortFnc: this.sortByPriority },
        { name: this.$t("EASEMEMNT_DATE_ID_LABEL"), active: false, sortFnc: this.sortByDateId },
        { name: this.$t("C_TRANS_TEXT"), active: false, sortFnc: this.sortByFile },
      ],
    };
  },

  computed: {
    propertiesSorted: function () {
      const a = this.properties;
      const id = parseInt(this.$router.history.current.params.id, 10);
      const found = a.find((e) => e.bfeNumber === id);
      const rest = a.filter((e) => e.bfeNumber !== id);
      const sorted = rest.sort((a, b) => a.labelText.localeCompare(b.labelText));
      return [found, ...sorted];
    },

    sortFunction: function () {
      return this.headers.find((e) => e.active).sortFnc;
    },

    propertyHasActs: function () {
      return this.easements.some((e) => e.easements.some((e) => e.filename != ""));
    },
  },

  mounted() {
    if (this.properties.length > 0) {
      this.setActiveId(0, this.properties[0]);
    }
  },

  methods: {
    setActiveId(id, property) {
      this.activeId = id;

      if (!this.getHasLoaded(property)) {
        this.loadEasements(property);
      }
    },

    getLoading(property) {
      return this.loadingStates.includes(property.bfeNumber) || false;
    },

    getHasLoaded(property) {
      return this.hasLoadedStates.includes(property.bfeNumber) || false;
    },

    getEasements(property) {
      let sortedEasements = [...(this.easements.find((e) => e.bfeNumber === property.bfeNumber)?.easements || [])]
        .filter(
          (e) =>
            this.getEasementTitle(e).match(new RegExp(this.searchTerm, "i")) ||
            this.getEasementDescription(e).match(new RegExp(this.searchTerm, "i")) ||
            this.getEasementDateId(e).match(new RegExp(this.searchTerm, "i"))
        )
        .sort(this.sortFunction);

      if (this.sortOrder === "desc") {
        sortedEasements = sortedEasements.reverse();
      }

      return sortedEasements;
    },
    getEasementDateId(easement) {
      return createEasementDateId(easement);
    },
    async loadEasements(property) {
      this.loadingStates.push(property.bfeNumber);

      const resp = await this.$apollo.query({
        query: searchByBFE,
        variables: {
          bfeNumber: parseInt(property.bfeNumber),
        },
      });

      if (resp.data.searchByBFE?.easements) {
        this.easements.push({ bfeNumber: property.bfeNumber, easements: resp.data.searchByBFE.easements });
      }

      this.hasLoadedStates.push(property.bfeNumber);
      this.loadingStates = this.loadingStates.filter((item) => item !== property.bfeNumber);
    },

    getEasementTitle(easement) {
      if (this.isEasementType(easement)) {
        return this.$t("EASEMENT_DEFAULT_NAME");
      } else {
        if (easement.texts && easement.texts.length > 0) {
          return easement.texts[0].headline;
        } else {
          return easement.textSummary;
        }
      }
    },

    getEasementDescription(easement) {
      if (easement.texts && easement.texts.length > 0) {
        return easement.texts
          .map((text, i) => {
            if (i === 0) {
              return text.section;
            } else {
              return `${text.headline} ${text.section}`;
            }
          })
          .join(" ");
      } else {
        return easement.textSummary;
      }
    },

    hasDownload(easement) {
      return this.isEasementType(easement);
    },

    isEasementType(easement) {
      return easement.documentIdentification && easement.documentIdentification.match(/-/g).length === 1;
    },

    hasAct(easement) {
      return !this.isEasementType(easement) && easement.filename;
    },

    selectSorting(sort) {
      this.headers.forEach((header) => {
        if (header === sort) {
          this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
        }
        header.active = header.sortFnc === sort.sortFnc;
      });
    },

    sortByPriority(a, b) {
      return a.priority - b.priority;
    },

    sortByDescription(a, b) {
      const descriptionA = this.getEasementDescription(a);
      const descriptionB = this.getEasementDescription(b);

      return descriptionA.localeCompare(descriptionB);
    },

    sortByFile(a, b) {
      return a.filename.localeCompare(b.filename);
    },

    sortByDateId(a, b) {
      return this.getEasementDateId(a).localeCompare(this.getEasementDateId(b));
    },

    downloadEasements(property) {
      let data = this.convertEasements(property);

      if (!data) return;

      const easementsSheet = createEasementsSheet(data, this.$i18n.locale);
      writeSheetsToXSLXAndSave([easementsSheet], "Download");
    },

    convertEasements(property) {
      const easements = this.easements.find((e) => e.bfeNumber === property.bfeNumber)?.easements || [];

      return easements.map((e) => {
        return {
          priority: e.priority,
          document: this.getEasementDescription(e),
          file: e.filename,
          dateId: this.getEasementDateId(e),
        };
      });
    },
    async openEstaidEasement(easement, property) {
      const resp = await this.$apollo.query({
        query: estaidEasementForCurrentUser,
        variables: {
          documentIdentification: easement.documentIdentification,
        },
        fetchPolicy: "no-cache",
      });

      const estaidEasement = resp.data.estaidEasementForCurrentUser;

      this.showEstaidEasements = true;

      this.activeEasement = { ...easement, estaidEasement: estaidEasement };

      this.$amplitude.openServitutfinder({
        postalCode: getPropertyAddress(property) ? getPropertyAddress(property).postalCode : "Unknown",
        propertyType: getPropertyType(property),
      });
    },
    async openLink(easement) {
      const query = this.isEasementType(easement) ? DownloadEasement : DownloadAct;
      const id = this.isEasementType(easement) ? easement.documentIdentification : easement.filename;

      const resp = await this.$apollo.query({
        query: query,
        variables: {
          id: id,
        },
      });

      if (resp.data.downloadEasement !== undefined || resp.data.downloadAct !== undefined)
        window.open(
          `https://${process.env.fileGateway}/downloads/${this.isEasementType(easement) ? resp.data.downloadEasement : resp.data.downloadAct}`,
          "_blank"
        );
    },
  },
};
