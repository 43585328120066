
import { getPropertyEasementsQuery, getPropertyCondosBfeQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import { getPropertyAddress } from "~/helpers/property-helpers.js";
import Easements from "../components/PropertyEasements.vue";

export default {
  title() {
    return `${this.$t("MENU_EASEMENTS")}`;
  },
  apollo: {
    property: getPropertyEasementsQuery,
    condos: getPropertyCondosBfeQuery,
  },
  components: {
    Easements,
  },
  computed: {
    properties: function () {
      let mainProperty = { ...this.property, labelText: this.getLabelText(this.property) };
      let condosMapped = this.condos.map((c) => {
        c.labelText = this.getLabelText(c);
        return c;
      });
      return [mainProperty, ...condosMapped];
    },
    loading: function () {
      return this.$apollo.queries.property.loading || this.$apollo.queries.condos.loading;
    },
  },
  methods: {
    getLabelText(property) {
      if (property.isDividedInCondos) {
        return this.$t("EASEMENT_UNIT_TITLE_MAIN");
      } else {
        let unit = property.unit ?? property.condo?.unit;
        let address = unit ? unit.address : getPropertyAddress(property);
        return !address ? this.$t("NO_ACCESS_ADDRESS") : this.$options.filters.addressLong(address);
      }
    },
  },
};
